<template>
  <router-view :key="$route.name"></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url('~@/assets/css/style.css');
@import url('~@/assets/css/desk.css');
@import url('~@/assets/css/tablet.css');
@import url('~@/assets/css/mobile.css');
/*@import url('~@/assets/css/modal.css');*/

</style>
