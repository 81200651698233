const noticeStore = {
    namespaced: true,
    state: {
        noticeList: [],
        noticeTotalCount: 0,
    },
    getters: {
        getNotice: state => state.noticeList,
        getNoticeTotalCount: state => state.noticeTotalCount,
    },
    mutations: {
        updateNotice: (state, payload) => {
            state.noticeList = payload;
        },
        updateNoticeCount: (state, payload) => {
            state.noticeTotalCount = payload;
        }
    },
    actions: {
        setNotice: ({ commit }, payload) => {
            commit('updateNotice', payload);
        },
        setNoticeTotalCount: ({ commit }, payload) => {
            commit('updateNoticeCount', payload);
        },
    }
}

export default noticeStore
