const loginStore = {
    namespaced: true,
    state: {
        isValidEmail: false,
        isValidPW: false,
        isLoginFail: false,
    },
    getters: {
        getEmailValid: state => state.isValidEmail,
        getPWValid: state => state.isValidPW,
        getLoginFail: state => state.isLoginFail,
    },
    mutations: {
        updateEmailValid: (state, payload) => {
            state.isValidEmail = payload;
        },
        updatePWValid: (state, payload) => {
            state.isValidPW = payload;
        },
        updateLoginFail: (state, payload) => {
            state.isLoginFail = payload;
        },
    },
    actions: {
        setEmailValid: ({ commit }, payload) => {
            commit('updateEmailValid', payload);
        },
        setPWValid: ({ commit }, payload) => {
            commit('updatePWValid', payload);
        },
        setLoginFail: ({ commit }, payload) => {
            commit('updateLoginFail', payload);
        },
        setInitilize:({ commit }) => {
            commit('updateEmailValid', false);
            commit('updatePWValid', false);
        },
    }
}

export default loginStore
