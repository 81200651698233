// router/index.js

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store";

Vue.use(VueRouter);
const webRoot = ""
const routes = [
    {
        path: webRoot + '/',
        name: 'KopexMain',
        component: () => import('../views/Home'),
        props: true
    },{
        path:webRoot+'/auth',
        name: 'AppAuthentication',
        component: () => import('../views/pages/Authentication'),
        props: true
    },{
        path:webRoot+'/assets',
        name: 'AssetsPage',
        component: () => import('../views/pages/Assets'),
        props: true
    },{
        path:webRoot+'/assets/register',
        name: 'AssetsRegister',
        component: () => import('../views/pages/AssetsDetail'),
        props: true
    },{
        path:webRoot+'/assets/:id',
        name: 'AssetsDetail',
        component: () => import('../views/pages/AssetsDetail'),
        props: true
    },{
        path:webRoot+'/change/pw',
        name: 'ChangePW',
        component: () => import('../views/pages/ChangePW'),
        props: true
    },{
        path:webRoot+'/find/id',
        name: 'FindID',
        component: () => import('../views/pages/FindIDPW'),
        props: true
    },{
        path:webRoot+'/find/pw',
        name: 'FindPW',
        component: () => import('../views/pages/FindIDPW'),
        props: true
    }, {
        path: webRoot + '/event',
        name: 'EventPage',
        component: () => import('../views/pages/Event'),  // 리스트
        props: true
    }, {
        path: webRoot + '/event/:id',
        name: 'EventDetail',
        component: () => import('../views/pages/EventDetail'), // 상세
        props: true
    },{
        path:webRoot+'/gas-station',
        name: 'GasStation',
        component: () => import('../views/pages/GasStation'),
        props: true
    },{
        path:webRoot+'/hot-topic/:location?',
        name: 'HotTopic',
        component: () => import('../views/pages/HotTopic'),
        props: true,
        beforeEnter: (to, from, next) => {
            if (!to.params.location) {
                next({ path: webRoot + '/hot-topic/place' });
            } else {
                next();
            }
        }
    },{
        path:webRoot+'/property/recommend/estate',
        name: 'RecommendProperty',
        component: () => import('../views/pages/RecommendProperty'),
        props: true
    },{
        path:webRoot+'/property/recommend/district',
        name: 'RecommendDistirct',
        component: () => import('../views/pages/RecommendDistrict'),
        props: true
    },{
        path:webRoot+'/property',
        name: 'PropertyDetailBase',
        component: () => import('../views/pages/Property'),
        children: [
            {
                path:'detail/:id',
                name: 'PropertyDetailId',
                component: () => import('../views/pages/Property'),  // 일반 조회
                props: true
            },
            {
                path:'estate/:location',
                name: 'PropertyDetailEstate',
                component: () => import('../views/pages/Property'),  // gnb 조회
                props: true
            },
            {
                path:'district/:location',
                name: 'PropertyDetailDistrict',
                component: () => import('../views/pages/Property'),  // gnb 조회
                props: true
            },
        ],
        props: true
    },{
        path:webRoot+'/property/register',
        name: 'PropertyRegister',
        component: () => import('../views/pages/PropertyRegister'),  // gnb 조회
        props: true
    },{
        path:webRoot+'/property/search',
        name: 'PropertySearch',
        component: () => import('../views/pages/PropertySearch'),  // gnb 조회
        props: true
    },{
        path:webRoot+'/property/interest',
        name: 'InterestProperty',
        component: () => import('../views/pages/InterestProperty'),
        props: true
    },{
        path:webRoot+'/tv',
        name: 'KopexTV',
        component: () => import('../views/pages/KopexTV'),
        props: true
    },{
        path:webRoot+'/benefit',
        name: 'BenefitPage',
        component: () => import('../views/pages/Benefit'),
        props: true
    },{
        path:webRoot+'/login',
        name: 'LoginPage',
        component: () => import('../views/pages/Login'),
        props: true
    },{
        path:webRoot+'/signup',
        name: 'SignUp',
        component: () => import('../views/pages/SignUp'),
        props: true
    },{
        path:webRoot+'/my-page',
        name: 'MyPage',
        component: () => import('../views/pages/MyPage'),
        props: true
    },{
        path:webRoot+'/my-page/edit',
        name: 'MyPageEdit',
        component: () => import('../views/pages/MyPageEdit'),
        props: true
    },{
        path:webRoot+'/my-page/withdraw',
        name: 'MyPageWithdraw',
        component: () => import('../views/pages/MyPageWithdraw'),
        props: true
    },{
        path:webRoot+'/my-pick',
        name: 'MyPick',
        component: () => import('../views/pages/MyPick'),
        children: [
            {
                path: 'recent/property',
                name: 'MyPickRecentProperty',
                props: { title: '최근 조회', type: 'property', category:'recent' }
            },
            {
                path: 'recent/district',
                name: 'MyPickRecentDistrict',
                props: { title: '최근 조회', type: 'district', category:'recent' }
            },
            {
                path: 'interest/property',
                name: 'MyPickInterestProperty',
                props: { title: '관심 목록', type: 'property', category:'interest' }
            },
            {
                path: 'interest/district',
                name: 'MyPickInterestDistrict',
                props: { title: '관심 목록', type: 'district', category:'interest' }
            }
        ],
/*
    },{
        path:webRoot+'/my-pick/regist',
        name: 'SearchPage',
        component: () => import('../views/pages/InterestRegister.vue'),
        props: true
*/
    },{
        path:webRoot+'/interest/search',
        name: 'InterestRegister',
        component: () => import('../views/pages/InterestRegister'),
        props: true
    },{
        path:webRoot+'/news',
        name: 'NewsPage',
        component: () => import('../views/pages/News'),
        props: true
    },{
        path:webRoot+'/payment',
        name: 'PaymentPage',
        component: () => import('../views/pages/Payment'),
        props: true
    },{
        path:webRoot+'/board',
        name: 'BoardPage',
        component: () => import('../views/pages/Board'),
        props: true
    },{
        path:webRoot+'/qna',
        name: 'BoardQna',
        component: () => import('../views/pages/BoardQna'),
        props: true
    },{
        path:webRoot+'/board/notice/:id',
        name: 'BoardDetailNotice',
        component: () => import('../views/pages/BoardDetail'),
        props: true
    },{
        path:webRoot+'/board/faq/:id',
        name: 'BoardDetailFaq',
        component: () => import('../views/pages/BoardDetail'),
        props: true
    },{
        path:webRoot+'/board/qna/:id',
        name: 'BoardDetailQna',
        component: () => import('../views/pages/BoardDetail'),
        props: true
    },{
        path: webRoot + '/privacy',
        name: 'PrivacyPage',
        component: ()=> import('../views/pages/policys/Privacy'),
        props:true
    },{
        path: webRoot + '/terms',
        name: 'TermsPage',
        component: ()=> import('../views/pages/policys/Terms'),
        props:true
    }, {
        path: webRoot + '/locations',
        name: 'LocationPage',
        component: () => import('../views/pages/policys/Location'),
        props: true
    }, {
        path: webRoot + '/settings',
        name: 'AppSettings',
        component: () => import('../views/pages/Settings'),
        props: true
    }, {
        path: webRoot + '/notification/:id',
        name: 'NotificationDetail',
        component: () => import('../views/pages/Notification'),
        props: true
    }, {
        path: webRoot + '/notification',
        name: 'NotificationPage',
        component: () => import('../views/pages/Notification'),
        props: true
/*
    }, {
        path: webRoot + '/estate',
        name: 'EstateMain',
        component: () => import('../views/page/EstateSubMain'),
        props: true
    }, {
        path: webRoot + '/estate_ranking',
        name: 'EstateRanking',
        component: () => import('../views/page/estate/EstateRanking'),
        props: false
    }, {
        path: webRoot + '/estate_detail',
        name: 'EstateDetail',
        component: () => import('../views/page/estate/EstateSubDetail'),
        props: false
    }, {
        path: webRoot + '/property_trends',
        name: 'PropertyTrends',
        component: () => import('../views/page/propertyTrends/PropertyTrends'),
        props: false
    }, {
        path: webRoot + '/district',
        name: 'DistrictMain',
        component: () => import('../views/page/DistrictSubMain'),
        props: true
    }, {
        path: webRoot + '/district_ranking',
        name: 'DistrictRanking',
        component: () => import('../views/page/district/DistrictRanking'),
        props: false
    }, {
        path: webRoot + '/district_detail',
        name: 'DistrictDetail',
        component: () => import('../views/page/district/DistrictSubDetail'),
        props: false
    }, {
        path: webRoot + '/payment',
        name: 'PaymentMain',
        component: () => import('../views/page/PaymentMain'),
        props: true
    }, {
        path: webRoot + '/payment_cancel',
        name: 'PaymentCancel',
        component: () => import('../views/page/payment/PaymentCancel'),
        props: true
    }, {
        path: webRoot + '/payment_result/:key',
        name: 'PaymentResult',
        component: () => import('../views/page/payment/PaymentResult'),
        props: true
    }, {
        path: webRoot + '/payment_complete/:key',
        name: 'PaymentComplete',
        component: () => import('../views/page/payment/PaymentComplete'),
        props: true
    }, {
        path: webRoot + '/partnership',
        name: 'PartnershipMain',
        component: () => import('../views/page/PartnershipMain'),
        props: true
    }, {
        path: webRoot + '/mypage',
        name: 'MypageMain',
        component: () => import('../views/page/MypageMain'),
        props: true
    }, {
        path: webRoot + '/veri/:id',
        name: 'VeriMain',
        component: () => import('../views/page/IntroMain'),
        props: true
    }, {
        path: webRoot + '/intro',
        name: 'Intro',
        component: () => import('../views/page/IntroMain'),
        props: true
    }, {
        path: webRoot + '/notice',
        name: 'NoticeList',
        component: () => import('../views/page/notice/NoticeList'),
        props: true
    }, {
        path: webRoot + '/notice_detail',
        name: 'NoticeDetail',
        component: () => import('../views/page/notice/NoticeDetail'),
        props: true
    }, {
        path: webRoot + '/question_detail',
        name: 'PersonalQuestionDetail',
        component: () => import('../views/page/personalQuestion/PersonalQuestionDetail'),
        props: true
    }, {
        path: webRoot + '/question_update',
        name: 'PersonalQuestionUpdate',
        component: () => import('../views/page/personalQuestion/PersonalQuestionUpdate'),
        props: true
    }, {
        path: webRoot + '/community',
        name: 'CommunityList',
        component: () => import('../views/page/community/CommunityList'),
        props: true
    }, {
        path: webRoot + '/community_detail',
        name: 'CommunityDetail',
        component: () => import('../views/page/community/CommunityDetail'),
        props: true
    }, {
        path: webRoot + '/community_add',
        name: 'CommunityAdd',
        component: () => import('../views/page/community/CommunityAdd'),
        props: true
    },{
        path: webRoot + '/privacy',
        name: 'Privacy.vue',
        component: () => import('../views/page/policys/Privacy.vue'),
        props: true
    }, {
        path: webRoot + '/terms',
        name: 'Terms',
        component: () => import('../views/page/policys/Terms'),
        props: true
    }, {
        path: webRoot + '/locations',
        name: 'Location',
        component: () => import('../views/page/policys/Location'),
        props: true
    },{
        path: webRoot + '/search_list',
        name: 'SearchList',
        component: () => import('../views/page/SearchResult'),
        props: true
    }, {
        /!* 이메일 인증 *!/
        // 이메일 인증 완료
        path: '/veri/already_email_verify',
        redirect: () => {
            return {
                name: 'Intro',
                params: {
                    id: 'complete_email_verify'
                }
            }
        },
    }, {
        // 이메일 인증이 이미 완료됨
        path: '/veri/already_email_verify',
        redirect: () => {
            return {
                name: 'Intro',
                params: {
                    id: 'already_email_verify'
                }
            }
        },
    }, {
        // 만료된 인증코드
        path: '/session_expired',
        redirect: () => {
            return {
                name: 'Intro',
                params: {
                    id: 'session_expired'
                }
            }
        },
*/
    // }, {
    //     // catchAll 라우트 정의
    //     path:webRoot+'/error',
    //     name: 'ErrorPage',
    //     component: () => import('../views/pages/Error'),
    //     props: true
    // }, {
    //     path: '/:catchAll(.*)', // 모든 경로에 대한 매칭
    //     redirect: '/error' // 에러 페이지로 리디렉션
    }, {
        path: webRoot + '/veri',
        name: 'VerificationStatus',
        component: () => import('../views/pages/errors/VerificationStatus'),
        props: true
    }, {
        path: webRoot + '/session_expired',
        name: 'VerificationStatus',
        component: () => import('../views/pages/errors/VerificationStatus'),
        props: true
    }, {
        path: webRoot + '/veri/complete_email_verify',
        name: 'VerificationStatus',
        component: () => import('../views/pages/errors/VerificationStatus'),
        props: true
    }, {
        path: webRoot + '/veri/already_email_verify',
        name: 'VerificationStatus',
        component: () => import('../views/pages/errors/VerificationStatus'),
        props: true
    }
];

const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior() {
        const element = document.getElementById('app');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
});

router.previousRoute = null;

router.beforeEach((to, from, next) => {
    store.dispatch('resetShareModal');

    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = '/static/images/favicon/android-icon-192x192.png';

    router.previousRoute = from.fullPath;

/*
    if (to.path.startsWith('/property/district') || to.path.startsWith('/property/recommend/district')) {
        alert('준비 중입니다');
        if (to.path !== '/') { // 현재 경로가 홈이 아닌 경우에만 리다이렉트
            next('/'); // 홈 페이지로 리다이렉트
        } else {
            next(); // 이미 홈 페이지에 있을 경우, 진행
        }
    } else {
        next(); // 다른 경로는 정상적으로 진행
    }
*/
    next();
});

export default router;