const communityStore = {
    namespaced: true,
    state: {
        communityList: [],
        communityTotalCount: 0,
    },
    getters: {
        getCommunity: state => state.communityList,
        getCommunityTotalCount: state => state.communityTotalCount,
    },
    mutations: {
        updateCommunity: (state, payload) => {
            state.communityList = payload;
        },
        updateCommunityCount: (state, payload) => {
            state.communityTotalCount = payload;
        }
    },
    actions: {
        setCommunity: ({ commit }, payload) => {
            commit('updateCommunity', payload);
        },
        setCommunityTotalCount: ({ commit }, payload) => {
            commit('updateCommunityCount', payload);
        },
    }
}

export default communityStore
