const faqStore = {
    namespaced: true,
    state: {
        faqList: [],
        faqTotalCount: 0,
    },
    getters: {
        getFaq: state => state.faqList,
        getFaqTotalCount: state => state.faqTotalCount,
    },
    mutations: {
        updateFaq: (state, payload) => {
            state.faqList = payload;
        },
        updateFaqCount: (state, payload) => {
            state.faqTotalCount = payload;
        }
    },
    actions: {
        setFaq: ({ commit }, payload) => {
            commit('updateFaq', payload);
        },
        setFaqTotalCount: ({ commit }, payload) => {
            commit('updateFaqCount', payload);
        },
    }
}

export default faqStore
