<script>
let is_location_idx = 0;
let vo_hostname = window.location.hostname;
let web_host_url = "";
let vo_port;
switch(vo_hostname) {
  case '127.0.0.1' :                    // Local
  case 'localhost' :                    // Local
    is_location_idx = 0;
    vo_port = window.location.port;
    web_host_url = "http://" + vo_hostname +":"+vo_port;
    break;
  case 'www.kopexindex.com' :             // Real WEB
  case 'www.kopexindex.co.kr' :           // Real WEB
  case 'kopexindex.com' :                 // Real WEB
  case 'kopexindex.co.kr' :               // Real WEB
    is_location_idx = 1;
    web_host_url = "https://" + vo_hostname;
    break;
  case '20.41.114.224' :                    // Dev
    is_location_idx = 2;
    vo_port = window.location.port;
    web_host_url = "http://" + vo_hostname +":"+vo_port;
    break;
}
let service_api_url = "http://localhost:8000";
switch (is_location_idx) {
  case 1 :    // REAL API
    service_api_url = "https://api.kopexindex.com";
    break;
  case 2 :    // DEV API
    service_api_url = "http://20.41.114.224:8000";
    break;
}

const web_root = "";
// let login_redirect_url = "https://newadmin.foodpang.co/admin/page/home";                                  // login Page - 사용 X
// if(is_location_idx == 2) login_redirect_url = "https://test-newadmin.foodpang.co/admin/page/home";        // after login redirect - 사용 X

const auth_userinfo_get_url = service_api_url + "/api/user";          // Kopex 로그인 유저 정보 조회 경로
const session_out_url = service_api_url + "/api/logout";              // Kopex 유저 로그아웃

const ACCESS_TOKEN_EXPIRE_HOUR = 6;                                               // 액세스 토큰 파기 기한 - Hour
const ACCESS_TOKEN_EXPIRE_TIME = ACCESS_TOKEN_EXPIRE_HOUR * 60 * 60 * 1000;       // 액세스 토큰 파기 기한 - 밀리세컨드

const ADDRESS_API_URL = "https://business.juso.go.kr/addrlink/addrLinkApi.do";
const ADDRESS_API_KEY = "devU01TX0FVVEgyMDIzMDExMTE3NDk1NTExMzQyMDE=";

const KAKAO_API_KEY = "ce0789dd548c7f6eab917528a8e4d405";

// var user_info = {};    // localStorage 기록

// console.log("=============== AAA");
// console.log(window.location.hostname);

export default {
  service_api_url
  , is_location_idx
  , auth_userinfo_get_url
  , ACCESS_TOKEN_EXPIRE_TIME
  , ADDRESS_API_URL
  , ADDRESS_API_KEY
  , web_root
  , session_out_url
  , KAKAO_API_KEY
}
</script>
