// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// 작성한 store 모듈
import findpwStore from "@/store/modules/findpwStore";
import modalStore from "@/store/modules/modalStore";
import loginStore from "@/store/modules/loginStore";
import joinStore from "@/store/modules/joinStore";
import searchboxStore from "@/store/modules/searchboxStore";
import secessionStore from "@/store/modules/secessionStore";
import noticeStore from "@/store/modules/noticeStore";
import communityStore from "@/store/modules/communityStore";
import faqStore from "@/store/modules/faqStore";

/* 전역 */
const store = new Vuex.Store({
    state: {
        isShareModalON: false,
        isMobile:false,
        isCardViewOpen:false,
        cardViewData: {
            type: '',
            id: '',
            data: null,
            selectedIdx: null
        },
        toastInfo:{
            isShow:false,
            msg:''
        },
        // TODO 이전 프로젝트 내용 옮겨옴. 확인 필요
        isModalOn:false,
        modalName:"login_popup",
        isExpirationOff: false,
        modalId:0,
        userInfoStore:{
            id:0,
            user_id:'',
            name:'',
            email:'',
            status:0,
        },
        userAccessInfoStore:false,
        isButtonDisabled: false,
        isUserStoreLoading: true,
        cancelTokens: [],
        isFavorite: false,
        reqInfo: "",
        isLoading: false,
    },
    getters:{
        getModalShow: state => state.isModalOn,
        getModalName: state => state.modalName,
        getExpirationOff: state => state.isExpirationOff,
        getModalId: state => state.modalId,
        getUserStore: state => state.userInfoStore,
        getUserAccessStore: state => state.userAccessInfoStore,
        getButtonDisabled: state => state.isButtonDisabled,
        getUserStoreLoading: state => state.isUserStoreLoading,
        getCancelTokens: state => state.cancelTokens,
        getFavorite: state => state.isFavorite,
        getReqInfo: state => state.reqInfo,
        getIsLoading: state => state.isLoading,
    },
    mutations: {
        toggleShareModal(state) {
            state.isShareModalON = !state.isShareModalON;
        },
        resetShareModal(state) {
            state.isShareModalON = false;
        },
        toggleFavorite(state) {
            state.isFavorite = !state.isFavorite;
        },
        toggleIsMobile(state, payload) {
            state.isMobile = payload;
        },
        toggleIsCardViewOpen(state, payload) {
            state.isCardViewOpen = payload;
        },
        setCardView(state, payload) {
            state.cardViewData = payload;
        },
        setToastInfo(state, payload) {
            state.toastInfo = payload;
        },
        setLoading(state, payload) {
            state.isLoading = payload;
        },

        // TODO 이전 프로젝트 내용 옮겨옴. 확인 필요
        updateModalShow: (state, payload) => {
            state.isModalOn = payload.isModalOn;
            state.modalName = payload.modalName;
            state.modalId = payload.modalId;
        },
        updateExpirationOff: (state, payload) => {
            state.isExpirationOff = payload;
        },
        updateUserStore: (state, payload) => {
            state.userInfoStore = payload;
        },
        updateUserAccessStore: (state, payload) => {
            state.userAccessInfoStore = payload;
        },
        updateButtonDisabled: (state, payload) => {
            state.isButtonDisabled = payload;
        },
        updateUserStoreLoading: (state, payload) => {
            state.isUserStoreLoading = payload;
        },
        updateCancelTokens: (state, payload) => {
            state.cancelTokens.push(payload);
        },
        initCancelTokens: (state) => {
            console.log("initCancelTokens");
            state.cancelTokens = [];
        },
        updateFavorite: (state, payload) => {
            state.isFavorite = payload;
        },
        updateReqInfo: (state, payload) => {
            state.reqInfo = payload;
        },
    },
    actions: {
        toggleShareModal({ commit }) {
            commit('toggleShareModal');
        },
        resetShareModal({ commit }) {
            commit('resetShareModal');
        },
        toggleFavorite({ commit }) {
            commit('toggleFavorite');
        },
        toggleIsMobile({ commit }) {
            commit('toggleIsMobile');
        },
        toggleIsCardViewOpen({ commit }) {
            commit('toggleIsCardViewOpen');
        },
        setCardView({ commit }, payload) {
            commit('setCardView',payload);
        },
        setToastInfo({ commit }, payload) {
            commit('setToastInfo',payload);
        },
        setLoading({ commit }, payload) {
            commit('setLoading',payload);
        },

        // TODO 이전 프로젝트 내용 옮겨옴. 확인 필요
        setModalShow: ({commit}, payload) => {
            commit('updateModalShow', payload);
        },
        setExpirationOff: ({ commit }, payload) => {
            commit('updateExpirationOff', payload);
        },
        setUserStore: ({ commit }, payload) => {
            commit('updateUserStore', payload);
            commit('updateButtonDisabled', true);
            commit('updateUserStoreLoading', false);
        },
        setUserAccessStore: ({ commit }, payload) => {
            commit('updateUserAccessStore', payload);
        },
        setUserStoreLoading: ({ commit }, payload) => {
            commit('updateUserStoreLoading', payload);
        },
        setFavorite: ({ commit }, payload) => {
            commit('updateFavorite', payload);
        },
        setReqInfo: ({ commit }, payload) => {
            commit('updateReqInfo', payload);
        },
    },
    modules: {
        findpwStore:findpwStore,
        modalStore:modalStore,
        loginStore:loginStore,
        joinStore:joinStore,
        searchboxStore:searchboxStore,
        secessionStore:secessionStore,
        noticeStore:noticeStore,
        communityStore:communityStore,
        faqStore:faqStore,
    },
});

export default store
