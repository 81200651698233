<!-- Utils.vue-->
<!-- 공통function -->
<script>
import router from "@/router";
import store from "@/store";
import {mapState} from "vuex";

export default {
  name: 'Utils',
  computed:{
    customFormatDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');

      return `${year}년 ${month}월 ${day}일`;
    },
    apiHostUrl(){
      let is_location_idx = 0;
      let vo_hostname = window.location.hostname;
      let web_host_url = "";
      let vo_port;
      switch(vo_hostname) {
        case '127.0.0.1' :                    // Local
        case 'localhost' :                    // Local
          is_location_idx = 0;
          vo_port = window.location.port;
          web_host_url = "http://" + vo_hostname +":"+vo_port;
          break;
        case 'www.kopexindex.com' :             // Real WEB
        case 'www.kopexindex.co.kr' :           // Real WEB
        case 'kopexindex.com' :                 // Real WEB
        case 'kopexindex.co.kr' :               // Real WEB
          is_location_idx = 1;
          web_host_url = "https://" + vo_hostname;
          break;
        case '20.41.114.224' :                    // Dev
          is_location_idx = 2;
          vo_port = window.location.port;
          web_host_url = "http://" + vo_hostname +":"+vo_port;
          break;
      }
      let service_api_url = "http://localhost:8000";
      switch (is_location_idx) {
        case 1 :    // REAL API
          service_api_url = "https://api.kopexindex.com";
          break;
        case 2 :    // DEV API
          service_api_url = "http://20.41.114.224:8000";
          break;
      }
      return service_api_url;
    },
    ...mapState(['userInfoStore']),
  },
  data () {
    return {
      API_URL: 'https://api.kopexindex.com',
      MAX_RESULTS: 10,
      DEFAULT_COLOR: 'blue',
      COMPANY_NAME: '아이큐',
      ACCESS_TOKEN_EXPIRE_HOUR: 6,                                               // 액세스 토큰 파기 기한 - Hour
      ACCESS_TOKEN_EXPIRE_TIME: this.ACCESS_TOKEN_EXPIRE_HOUR * 60 * 60 * 1000,       // 액세스 토큰 파기 기한 - 밀리세컨드
      ADDRESS_API_URL: "https://business.juso.go.kr/addrlink/addrLinkApi.do",
      ADDRESS_API_KEY: "devU01TX0FVVEgyMDIzMDExMTE3NDk1NTExMzQyMDE=",
      KAKAO_API_KEY: process.env.VUE_APP_KAKAO_API_KEY,
      itemsPerPage: 20, // 한 페이지에 보여줄 데이터 개수
      itemsPerPageSmall: 5, // 한 페이지에 보여줄 데이터 개수
      itemsPerPageCard: 6, // 한 페이지에 보여줄 데이터 개수
      menu:{
        propertyBuilding:{
          menuName:"부동산 지수",
          data:[
            {
              content:"전국 지수",
              info:{
                title:"전국 지수",
                indices: {data:"109.02",class:"font-black"},
                rate:"0.00%",
                type:"부동산",
                location:"전국",
              },
              page:"PropertyDetailEstate"
            },
            {
              content:"서울 지수",
              info:{
                title:"서울시 지수",
                indices: {data:"102.31",class:"font-red"},
                rate:"▲0.02%",
                type:"부동산",
                location:"서울",
              },
              page:"PropertyDetailEstate"
            },
            {
              content:"강남 지수",
              info:{
                title:"강남 지수",
                indices: {data:"107.04",class:"font-red"},
                rate:"▲0.22%",
                type:"부동산",
                location:"강남",
              },
              page:"PropertyDetailEstate"
            },
            {
              content:"추천 부동산",
              info:{
                type:"부동산",
                title:"추천 부동산",
              },
              page:"RecommendProperty"
            },
          ]
        },
/*
        propertyDistrict:{
          menuName:"상권 지수",
          data:[
            {
              content:"전국 지수",
              info:{
                title:"전국 지수",
                indices: {data:"109.02",class:"font-black"},
                rate:"0.00%",
                type:"상권",
                location:"전국",
              },
              page:"PropertyDetailDistrict"
            },
            {
              content:"서울 지수",
              info:{
                title:"서울시 지수",
                indices: {data:"102.31",class:"font-red"},
                rate:"▲0.02%",
                type:"상권",
                location:"서울",
              },
              page:"PropertyDetailDistrict"
            },
            {
              content:"강남 지수",
              info:{
                title:"강남 지수",
                indices: {data:"107.04",class:"font-red"},
                rate:"▲0.22%",
                type:"상권",
                location:"강남",
              },
              page:"PropertyDetailDistrict"
            },
            {
              content:"추천 상권",
              info:{
                type:"상권",
                title:"추천 상권",
              },
              page:"RecommendDistirct"
            },
          ]
        },
*/
        myPick:{
          menuName:"마이PICK",
          data:[
            {
              content:"최근 조회",
              info:{
                title:"최근 조회",
                text:"조회",
                type:"property"
              },
              page:"MyPickRecentProperty"
            },
            {
              content:"관심 목록",
              info:{
                title:"관심 목록",
                text:"관심",
                type:"property"
              },
              page:"MyPickInterestProperty"
            },
          ]
        },
        hotTopic:{
          menuName:"HOT토픽", // shopping
          data:[
            {
              content:"오늘의 BEST 상권",
              info:{
                type:"place",
                title:"오늘의 BEST 상권",
                location:"place",
              },
              page:"HotTopic"
            },
            {
              content:"업종별 인기 상권",
              info:{
                type:"industry",
                title:"업종별 인기 상권",
                location:"industry",
              },
              page:"HotTopic"
            },
            {
              content:"성별 인기 상권",
              info:{
                type:"revenueGender",
                title:"성별 인기 상권",
                location:"revenueGender",
              },
              page:"HotTopic"
            },
            {
              content:"시간대별 인기상권",
              info:{
                type:"revenueTime",
                title:"시간대별 인기상권",
                location:"revenueTime",
              },
              page:"HotTopic"
            },
            {
              content:"요일별 인기 상권",
              info:{
                type:"revenueWeek",
                title:"요일별 인기 상권",
                location:"revenueWeek",
              },
              page:"HotTopic"
            },
            {
              content:"연령별 인기 상권",
              info:{
                type:"revenueAge",
                title:"연령별 인기 상권",
                location:"revenueAge",
              },
              page:"HotTopic"
            },
            {
              content:"최저가 주유소",
              info:{
                type:"revenueAge",
                title:"최저가 주유소",
              },
              page:"GasStation"
            },
          ]
        },
/*
        assets:{
          menuName:"자산",
          data:[
            {
              content:"자산 등록",
              info:{
                type:"register",
              },
              query:{step:1,tab1:1,tab2:1},
              page:"AssetsRegister"
            },
            {
              content:"자산 현황",
              info:{
                type:"inquiry"
              },
              page:"AssetsPage"
            },
          ]
        },
*/
/*
        events:{
          menuName:"이벤트",
          data:[
            {
              content:"진행 이벤트",
              info:{
                title:"이벤트",
                type:"inProgress"
              },
              page:"EventPage"
            },
            {
              content:"종료 이벤트",
              info:{
                title:"이벤트",
                type:"termination"
              },
              page:"EventPage"
            },
          ]
        },
*/
        board:{
          menuName:"게시판",
          data:[
            {
              content:"공지사항",
              info:{
                type:"notice"
              },
              query:{type:'notice'},
              page:"BoardPage"
            },
            {
              content:"1:1문의",
              info:{
                type:"qna"
              },
              page:"BoardQna"
            },
            {
              content:"FAQ",
              info:{
                type:"faq"
              },
              query:{type:'faq'},
              page:"BoardPage"
            },
          ]
        },
        news:{
          menuName:"뉴스",
          data:[
            {
              content:"뉴스",
              title:"뉴스",
              info:{
                type:"all"
              },
              page:"NewsPage"
            },
/*
            {
              content:"부동산 뉴스",
              title:"부동산 뉴스",
              info:{
                type:"property"
              },
              page:"NewsPage"
            },
            {
              content:"상권 뉴스",
              title:"상권 뉴스",
              info:{
                type:"district"
              },
              page:"NewsPage"
            },
*/
          ]
        },
        kopexTV:{
          menuName:"코펙스TV",
          data:[
            {
              content:"코펙스TV",
              info:{
                type:"list"
              },
              page:"KopexTV"
            },
          ]
        },
/*
        benefit:{
          menuName:"혜택",
          data:[
            {
              content:"혜택",
              info:{
                type:"list"
              },
              page:"BenefitPage"
            },
          ]
        },
*/
      },
      youtube:[
        {
          "title": "뉴 핫 플레이스의 형성 과정과 선진입해야 할 투자처는?",
          "link": "https://youtu.be/-XIIto7Pgog?si=Jo8WxbHpOPBEnWr",
          "thumbnail": "youtubethumbnail1.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "24.05.30"
        },
        {
          "title": "한강 조망의 가치와 가격 선정",
          "link": "https://youtu.be/NeX9UDHhMHc?si=xwbjJc8FEfD6rNh8",
          "thumbnail": "youtubethumbnail2.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "24.04.16"
        },
        {
          "title": "우리가 지금 현재 '개포동'을 주목해야하는 이유!?",
          "link": "https://youtu.be/ZV3j-f1xiYg?si=YnUMvAjp7hfsoEkh",
          "thumbnail": "youtubethumbnail3.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "24.03.29"
        },
        {
          "title": "송파 재건축 리모델링 아파트를 주목해라",
          "link": "https://youtu.be/TEtqZPGquFQ?si=pSep7Rs5iuGZZzOI",
          "thumbnail": "youtubethumbnail4.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "23.11.03"
        },
        {
          "title": "왜 주거지로 잠실을 선호하는가?",
          "link": "https://youtu.be/hY5SzMJ5pmM?si=rbL4WSkESin8bnxW",
          "thumbnail": "youtubethumbnail5.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "23.09.26"
        },
        {
          "title": "부동산 전문 투자자가 알려주는 이문휘경 뉴타운 확실하게 투자",
          "link": "https://youtu.be/D3hSO1i3z5I?si=SYtMi5YOJmKafXIn",
          "thumbnail": "youtubethumbnail6.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "23.08.20"
        },
        {
          "title": "강동구 고덕동 이곳이 오른다! 2억대 수익 매물 강력추천",
          "link": "https://youtu.be/aFpQRBHpdTM?si=6yhrp09-UeUR-SVg",
          "thumbnail": "youtubethumbnail7.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "23.07.07"
        },
        {
          "title": "미래를 보고 투자해라 서부이촌동이 곧 미래다!",
          "link": "https://youtu.be/JcHSdsQC2SM?si=_I1_DeVYf3CgHwp0",
          "thumbnail": "youtubethumbnail8.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "23.06.24"
        },
        {
          "title": "동부이촌동 전격분석 부동산 전문 투자자가 알려줍니다.",
          "link": "https://youtu.be/WOH_eYOVpso?si=cqzllcefNO9IcYKy",
          "thumbnail": "youtubethumbnail9.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "23.03.14"
        },
        {
          "title": "부동산 전문 투자자가 직접 가보는 임장 V-log(금호동 재개발)",
          "link": "https://youtu.be/wUsqwgCeYdU?si=sSP_3BizjkP_2hq7",
          "thumbnail": "youtubethumbnail10.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.11.03"
        },
        {
          "title": "부동산 전문 투자자가 직접 가보는 임장 V-log (한남 하이츠)",
          "link": "https://youtu.be/SRYvgMEM4R4?si=EotiptuJI0O0eLf6",
          "thumbnail": "youtubethumbnail11.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.10.28"
        },
        {
          "title": "부동산 전문 투자자가 알려주는 2022년 하반기 알짜 투자정보!",
          "link": "https://youtu.be/YIz3VYl0ETU?si=rx2sPMbdZF441jqd",
          "thumbnail": "youtubethumbnail12.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.10.22"
        },
        {
          "title": "부동산 전문 투자자가 직접 가보는 임장 V-log(왕십리 마장)",
          "link": "https://youtu.be/di9hvUx8erY?si=PosP_T7GXrKW9UDF",
          "thumbnail": "youtubethumbnail13.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.10.12"
        },
        {
          "title": "부동산 전문 투자자가 직접 가보는 임장 V-log(용산파크웨이)",
          "link": "https://youtu.be/BYVbbba7gxw?si=kImUualZEPMoC-Q5",
          "thumbnail": "youtubethumbnail14.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.09.29"
        },
        {
          "title": "절대 쫄지마라! 지금이 부동산 투자 타이밍, 하이엔드 오피스텔 투자 방법",
          "link": "https://youtu.be/uQT2pTQEKpw?si=0I90YRr_7NrAulj1",
          "thumbnail": "youtubethumbnail15.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.09.06"
        },
        {
          "title": "부동산 전문 투자자가 알려주는 *정보사 부지 개발 집중 분석",
          "link": "https://youtu.be/fFHKRmNcsKo?si=OTGTl5yY-ZGSAhuy",
          "thumbnail": "youtubethumbnail16.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.08.20"
        },
        {
          "title": "구로 차량 기지이전 이슈! 아직 늦지 않았습니다!",
          "link": "https://youtu.be/1OuyprhpqOw?feature=shared",
          "thumbnail": "youtubethumbnail17.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.07.28"
        },
        {
          "title": "부동산 전문 투자자가 알려주는 재건축과 리모델링의 차이점",
          "link": "https://youtu.be/NUMwSWs1X90?feature=shared",
          "thumbnail": "youtubethumbnail18.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.07.10"
        },
        {
          "title": "부동산 전문 투자자가 알려주는 GBC, 영동대로 개발, 국제교류 복합지구",
          "link": "https://youtu.be/MVy8F6UIw8w?feature=shared",
          "thumbnail": "youtubethumbnail19.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.06.21"
        },
        {
          "title": "한남 뉴타운 구역별 투자 지역! 지금 사세요!",
          "link": "https://youtu.be/tAcNcTHD--g?feature=shared",
          "thumbnail": "youtubethumbnail20.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.05.27"
        },
        {
          "title": "서울대입구 봉천동 OO-O구역 앞으로 15억 된다?!",
          "link": "https://youtu.be/yXMknsm2c5g?feature=shared",
          "thumbnail": "youtubethumbnail21.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.05.17"
        },
        {
          "title": "부동산 전문 투자자가 알려주는 (신안산선/신길 뉴타운/보라매 역세권)",
          "link": "https://youtu.be/db1-zxWHfb0?feature=shared",
          "thumbnail": "youtubethumbnail22.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.05.02"
        },
        {
          "title": "부동산 전문 투자자가 알려주는 (성수전략정비구역/성수 뉴타운/트리마제)",
          "link": "https://youtu.be/HkBvYDfnuac?feature=shared",
          "thumbnail": "youtubethumbnail23.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.04.23"
        },
        {
          "title": "국회의사당 앞이 개발이 된다고? (국회 의사당 세종시 이전 이슈)",
          "link": "https://youtu.be/9vIooXwa4wA?feature=shared",
          "thumbnail": "youtubethumbnail24.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "24.04.11"
        },
        {
          "title": "목동 뭐사야될까? 부동산 전문가가 알려주는 (목운중학교/목동학원가)",
          "link": "https://youtu.be/SUGWL8B5cWo?feature=shared",
          "thumbnail": "youtubethumbnail25.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "24.04.05"
        },
        {
          "title": "성북구 길음동? 이거 사면돼 내 보유매물 등기를 하나깔께",
          "link": "https://youtu.be/B4Tw3bwBJwA?feature=shared",
          "thumbnail": "youtubethumbnail26.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.03.30"
        },
        {
          "title": "왕십리역 GTX 개통 호재/앙십리역 주변 투자 매물/서울숲 세림 아파트",
          "link": "https://youtu.be/zOCaebEvAZo?feature=shared",
          "thumbnail": "youtubethumbnail27.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.03.23"
        },
        {
          "title": "부동산 전문 투자자가 알려주는 (신림 공공재개발/신속통합기획/신림선 수혜지)",
          "link": "https://youtu.be/RoXlCnNZMWE?feature=shared",
          "thumbnail": "youtubethumbnail28.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.03.16"
        },
        {
          "title": "2022년 서울 용산공원/서울역 북부 역세권 호재 대박 부동산 정보 대공개",
          "link": "https://youtu.be/mH2laXoGTTs?feature=shared",
          "thumbnail": "youtubethumbnail29.jpeg",
          "channel": "아파트 찍어주는 오빠",
          "registerTime": "22.03.07"
        }
      ],
      sidoMappingList: {
        "전체": "-1", // "전체"
        "서울": "1100000000", // "서울특별시"
        "강남": "1168000000", // "서울특별시 강남구"
        "부산": "2600000000", // "부산광역시"
        "인천": "2800000000", // "인천광역시"
        "대구": "2700000000", // "대구광역시"
        "대전": "3000000000", // "대전광역시"
        "광주": "2900000000", // "광주광역시"
        "울산": "3100000000", // "울산광역시"
        "세종": "3600000000", // "세종특별자치시"
        "경기": "4100000000", // "경기도"
        "강원": "4200000000", // "강원도"
        "충북": "4300000000", // "충청북도"
        "충남": "4400000000", // "충청남도"
        "경북": "4700000000", // "경상북도"
        "경남": "4800000000", // "경상남도"
        "전북": "4500000000", // "전라북도"
        "전남": "4600000000", // "전라남도"
        "제주": "5000000000", // "제주특별자치도"
      },
      news:[
        {
          title:'대구 범어 아이파크 11일 1순위 청약 접수',
          link:'https://n.news.naver.com/article/277/0005402308',
          registerTime:'2024-04-25',
        },
        {
          title:'고금리에 경매행 늘어… 3월 집합건물 임의 경매 11년 만에 최대',
          link:'https://n.news.naver.com/article/018/0005707882',
          registerTime:'2024-04-26',
        },
        {
          title:'대우건설, 평택 푸르지오 센터파인 ‘선착순 동호 지정 계약 시작',
          link:'https://n.news.naver.com/article/421/0007460677',
          registerTime:'2024-04-27',
        },
        {
          title:'‘팝업스토어’ 부동산 시장의 새로운 트렌드',
          link:'https://n.news.naver.com/article/015/0004969214',
          registerTime:'2024-04-28',
        },
        {
          title:'반도건설, 부산 최대 지식산업센터 ‘ 에코델타 시티  반도 아이비플래닛’ 독점',
          link:'https://n.news.naver.com/article/011/0004323976',
          registerTime:'2024-04-29',
        },
      ],
    }
  },
  methods:{
    formattedDate(dateString){
      if(!dateString) return '-';
      const date = new Date(dateString);
      if(date == 'Invalid Date') return '-';
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`??'-';
    },
    formattedContent(content) {
      return content.replace(/\n/g, '<br>');
    },
    async checkLoginStatus() {
      const accessToken = localStorage.getItem("kopex_access_token");
      if (accessToken) {
        await this.getUserInfo()
      } else {
        alert('로그인이 필요한 서비스입니다.');
        this.routerGoPush({page:'LoginPage',info:{title:'로그인'}})
        return null;
      }
      const userInfo = this.userInfoStore;
      console.log('checkLoginStatus',userInfo)
      if (!userInfo || userInfo.id == 0) {
         alert('로그인이 필요한 서비스입니다.');
         this.routerGoPush({page:'LoginPage',info:{title:'로그인'}})
        return null;
       }
       return userInfo;
     },
     validEmail(email) {
       const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       return re.test(email);
     },
     validPassword(password) {
       const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
       return re.test(password);
     },
     validPhoneNumber(phoneNumber) {
       // 전화번호 형식 검사 (예시: 010-1234-5678)
       const re = /^010-\d{3,4}-\d{4}$/;
       return re.test(phoneNumber);
     },
    formatFixed2:function(num){
      return num.toFixed(2);
    },
    formatUpDown:function(num, prefix = true){
      if (num === undefined) return (prefix?'▲ ':'')+'0.00'
      if (typeof num == 'string') {
        // 기호가 없는 숫자 문자열을 찾기 위해 정규 표현식을 사용
        const matches = num.match(/^-?\d+(\.\d+)?%?$/); // 부호가 있는 정수나 소수 부분만 포함된 숫자 문자열을 찾음
        if (matches) {
          // 숫자 문자열을 부동 소수점 숫자로 변환
          const number = parseFloat(num);
          // 기호를 추가하여 포맷팅
          return (number < 0 ? '▼' : '▲') + Math.abs(number).toFixed(2) + num.replace(/^-?\d+(\.\d+)?/, '');
        }
        // 기호가 포함되어 있는 문자열은 그대로 반환
        return num;
      }
      return (prefix?(num < 0 ? '▼ ' : '▲ '):'') + Math.abs(num).toFixed(2)
    },
    formatPercent:function(num){
      return num.toFixed(2)+'%';
    },
     formatNumber:function(num,fixed = 2){
       if (num !== undefined && num !== null) {
         // num이 문자열이면 숫자로 변환
         num = typeof num === 'string' ? Number(num) : num;

         // 소수점 둘째 자리까지 고정
         let parts = num.toFixed(fixed).split('.');

         // 정수 부분에만 쉼표 추가
         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

         // 소수점과 결합하여 반환
         return parts.join('.');
       }
       else return 0
     },
     saveParamsToLocalStorage(data) {
       localStorage.setItem(data.page, JSON.stringify(data));
     },
     getParamsFromLocalStorage(pageName) {
       // console.log('getParamsFromLocalStorage',this.$route.name)
       const data = localStorage.getItem(pageName);
       if (data) {
         return JSON.parse(data);
       }
       return null;
     },
     routerGoPush(data){
       // const currData = this.getParamsFromLocalStorage(data.page)
       this.saveParamsToLocalStorage(data)
       this.routerPush(data)
     },
     routerPush(data){
       console.log("routerPush", data)
       if (data.info && data.info.id) {
         data.id = data.info.id;
         switch (data.info.type) {
           case 'notice':
             data.page = "BoardDetailNotice"
             break;
           case 'qna':
             data.page = "BoardDetailQna"
             break;
           case 'faq':
             data.page = "BoardDetailFaq"
             break;
           case 'detail':
             // data.page = "data.page"
             break;
           default:
             break;
         }
       }
       this.saveParamsToLocalStorage(data)

       if (data.info && data.info.location) {
         data.location = data.info.location;
       }
       if(router.currentRoute.name == data.page
           && (data.id && router.currentRoute.params.id != data.id)
           && router.currentRoute.params.location != data.location
           && data.query) {
         // 특수페이지
         if (data.page == 'MyPage') return;
         console.log('=========router.go')
         router.go(router.currentRoute.name);
         return;
       } else if (router.currentRoute.name == data.page
           && data.query
           && !this.compareObjects(router.currentRoute.query, data.query)) {
         console.log('=========router.push',router.currentRoute)
         router.push({
           name: data.page,
           params: data,
           query: data.query ?? null
         });
         return;
       } else if (router.currentRoute.name == data.page
           && (router.currentRoute.params.location != data.location
           || router.currentRoute.params.id != data.id)) {
         console.log('=========router.push',router.currentRoute)
         router.push({
           name: data.page,
           params: data,
         });
         return;
       } else if (router.currentRoute.name == data.page) {
         console.log('=========아무것도 안함', router.currentRoute,data)
         return;
       }
/*
        if (router.currentRoute.name == data.page &&
            !this.compareObjects(router.currentRoute.query, data.query)) {
          console.log('=========router.replace')
          router.replace({
            path: router.currentRoute.path,
            params: data,
            query: data.query ?? null
          })
          return;
        }
*/



         console.log('++++++++++router.push',router.currentRoute)
         router.push({
           name: data.page,
           params: data,
           query: data.query ?? null
         });
     },
     compareObjects(obj1, obj2) {
       if (!obj1 && !obj2) return true;
       if (!obj2) return false;
       // 먼저 각 객체의 키(key) 배열을 가져옵니다.
       const keys1 = Object.keys(obj1);
       const keys2 = Object.keys(obj2);

       // 키(key) 배열의 길이가 다르면 두 객체는 다릅니다.
       if (keys1.length !== keys2.length) {
         return false;
       }

       // 각 키(key)에 대해 값(value)을 비교합니다.
       for (let key of keys1) {
         // 값(value)을 문자열로 변환하여 비교합니다.
         if (String(obj1[key]) !== String(obj2[key])) {
           return false;
         }
       }

       // 모든 키(key)의 값(value)이 일치하면 true를 반환합니다.
       return true;
     },
     onClickRedirect(url){
       window.open(url, "_blank");
     },
     scrollToTop(){
       const element = document.getElementById('app');
       if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
       }
     },
     alertPeparing(){
       alert('서비스를 준비 중입니다.');
     },
     getAccessToken:async function(userId, password) {
       return await this.$axios.post("/login", {
         "user_id": userId,
         "password": password,
       }, {}).then((res) => {
         console.log('login success',res)
         localStorage.setItem("kopex_access_token", res.data.token);      // Access Token - LocalStorage 기록
         localStorage.setItem("expire_experiance", "ok");
         return res;
       }).catch((err) => {
         err.status = err.response.status
         return err;
       });
     },
     getUserInfo:async function(token) {
       // 1. 토큰 유효성 검증
       // 1-1. 토큰이 유효하면
       // 1-2. 토큰이 유효하지 않으면 localstorage 지우고 홈화면으로 보내기
       // 2. 1-1. 통과 시 사용자 store 정보 확인
       // 2-1. 사용자 store가 비어있는 경우 api 호출
       // 2-2. 사용자 정보 반환
       /** 1. 가지고 있는 토큰 유효성 검증 */
       let accessToken = localStorage.getItem("kopex_access_token");
       // 토큰이 존재하지 않으면
       if(accessToken == undefined || accessToken == "" || accessToken == null) {
         // 방어코드 access_token이 undefined 일 경우, localStorage 비움
         store.dispatch('setUserStore','');
         localStorage.removeItem('kopex_access_token');
         return;
       }
       // 토큰이 존재하면
       if(accessToken) {
         // 유효기간이 만료된 경우
         const base64Url = accessToken.split('.')[1]; // JWT 토큰에서 페이로드 부분 추출
         const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // URL 안전한 base64 문자열로 변환
         const payload = JSON.parse(atob(base64)); // base64를 디코딩하고 JSON을 파싱
         const currentTime = Math.floor(Date.now() / 1000); // 밀리초 단위를 초 단위로 변환

         if(currentTime > payload.exp) {
           console.log("Access Token Expire");
           store.dispatch('setUserStore',{
             id: 0,
             user_id: '',
             name: '',
             email: '',
             status: 0
           });
           localStorage.removeItem('kopex_access_token');
           return;
         }
       }

       /** 2. 사용자 store 확인 및 업데이트 */
       // 사용자 store가 정상적으로 값이 있으면
       let userInfo = store.getters.getUserStore;
       if (userInfo.id !== null && userInfo.id !== undefined && userInfo.id !== 0) {
         return userInfo;
       }
       // 사용자 store가 값이 없으면 API에서 유저 정보 가져오기
       return await this.$axios.get("/user",{
         headers: {
           "Context-Type": "application/json",
           "Authorization": "Bearer " + accessToken,
         }
       }).then(res=>{
         if (res.data.data.email_verify === false) {
           localStorage.removeItem('kopex_access_token');
           store.dispatch('setUserStore', '');
           alert('이메일이 인증되지 않았습니다. 이메일 인증 후 로그인을 진행해주시기 바랍니다.');
           this.routerGoPush({page:'VerificationStatus',info:{title:'이메일 인증'}})
         }
         store.dispatch('setUserStore',res.data.data);
         userInfo = store.getters.getUserStore;
         return userInfo;
       }).catch(err=> {
         localStorage.removeItem('kopex_access_token');
         store.dispatch('setUserStore', '');
         return false;
       });
     },
     // 로그아웃 Submit
     loginOutSubmit:async function() {
      console.log("loginOutSubmit")
       let vo_token = localStorage.getItem('kopex_access_token')
       if(!vo_token) {
         // 이미 로그아웃이 된 상태 (예외처리)
         store.dispatch('setUserStore',{
           id: 0,
           user_id: '',
           name: '',
           email: '',
           status: 0
         });
         localStorage.removeItem('kopex_access_token');
         // console.log("loginOutSubmit - aleady LogOut");
         return;
       }

       await this.$axios.get("/logout",{
         headers: {
           "Authorization": "Bearer " + vo_token,
         }
       }).then(res=>{
         store.dispatch('setUserStore',{
           id: 0,
           user_id: '',
           name: '',
           email: '',
           status: 0
         });
         // access_token 지우기
         localStorage.removeItem('kopex_access_token');
         return res;
       }).catch(err=>{
         store.dispatch('setUserStore',{
           id: 0,
           user_id: '',
           name: '',
           email: '',
           status: 0
         });
         localStorage.removeItem('kopex_access_token');
         // console.log("loginOutSubmit - aleady LogOut");
         let error_result = err.response;
         err.success = 0;
         err.error_list = error_result.errors;
         return err;
       });
     },
     async getInterest(){
       let vo_token = localStorage.getItem("kopex_access_token");                         // Access Token - Read
       if (!vo_token) return [];

       return await this.$axios.get('/favorite_list',{
         headers:{
           'Authorization': 'Bearer ' + vo_token,
         },
       }).then((res)=>{
         console.log('getInterest',res)
         if (this.interestProperty!= undefined
             && this.interestDistrict != undefined) {
           res.data.data.forEach(item => {
             if (item.type == 1) {
               this.interestProperty.push(item);
             } else {
               this.interestDistrict.push(item);
             }
           });
         }
         return res.data.data;
       }).catch((err)=>{
         return [];
       });
     },
     async getRecentInquiry(){
       let vo_token = localStorage.getItem("kopex_access_token");// Access Token - Read
       if (!vo_token) return [];

       return await this.$axios.get('/lookup_list',{
         headers:{
           'Authorization': 'Bearer ' + vo_token,
         },
       }).then((res)=>{
         if (this.recentInquiryProperty!= undefined
         && this.recentInquiryDistrict != undefined) {
           res.data.data.forEach(item => {
             if (item.type === 1) {
               this.recentInquiryProperty.push(item);
             } else if (item.type === 2) {
               this.recentInquiryDistrict.push(item);
             }
           });
         }
         return res.data.data
       }).catch((err)=>{
         return [];
       });
     },
   },
}
</script>
