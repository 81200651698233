const modalStore = {
    namespaced: true,
    state: {
        findPWEmail: "",
        joinEmail: "",
        resend: false,
    },
    getters: {
        getPWEmail: state => state.findPWEmail,
        getJoinEmail: state => state.joinEmail,
        getresend: state => state.resend,
    },
    mutations: {
        updatePWEmail: (state, payload) => {
            state.findPWEmail = payload;
        },
        updateJoinEmail: (state, payload) => {
            state.joinEmail = payload;
        },
        updateResend: (state, payload) => {
            state.resend = payload;
        },
    },
    actions: {
        setPWEmail: ({ commit }, payload) => {
            commit('updatePWEmail', payload);
        },
        setJoinEmail: ({ commit }, payload) => {
            commit('updateJoinEmail', payload);
        },
        setResend: ({ commit }, payload) => {
            commit('updateResend', payload);
        },
        setModalInitialize:({ commit }) => {
            commit('updatePWEmail', "");
        },
    }
}

export default modalStore
