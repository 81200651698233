//main.js

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Utils from "@/components/Utils";
import Global from "@/components/Global";
import store from '@/store';
import axios from 'axios';

// Font Awesome 관련 임포트
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// 사용하는 아이콘 임포트 (필요에 따라 추가 가능)
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// 아이콘을 라이브러리에 추가
library.add(fas, far, fab);

// FontAwesomeIcon 컴포넌트를 전역 컴포넌트로 등록
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.mixin(Utils);

// console.log(Global.service_api_url);
// Axios 인스턴스 생성
const axiosInstance = axios.create({
    baseURL: Global.service_api_url+'/api',//'https://api.kopexindex.com',
    timeout: 50000, // 타임아웃 설정 10초
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
    // withCredentials:true, // 자격증명이 필요한 경우 Access-Control-Allow-Origin 에 와일드카드(*) 대신 특정 도메인으로 설정해야함
    proxy: {
        // url 리소스를 추가해주자
        "/api": {
            // 해당 리소스가 있는 url일 경우 타겟으로 baseURL을 변경
            target: Global.service_api_url,
            // 기본 베이스URL을 바꿔줄지 여부
            changeOrigin: true
        },
    }
});
// 요청 인터셉터 추가 (요청 전에 실행됨)
axiosInstance.interceptors.request.use(
    config => {
        // store.commit('setLoading', true); // Vuex 스토어를 사용하는 경우

        /*
        if (config.url != "/user" && config.url != "/is_acceable") {
            // 다음 페이지로 이동 시 이전에 실행 중인 axios 전체 종료
            // CancelToken 생성
            const cancelToken = axios.CancelToken.source();

            // 요청 객체에 CancelToken 할당
            config.cancelToken = cancelToken;

            // CancelToken을 Vuex 스토어에 저장
            store.commit('updateCancelTokens', config);
        }
        */

        // 요청 전 처리 작업을 수행할 수 있습니다.
        // console.log("시작 인터셉터",config.url);
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터 추가 (응답 이후 실행됨)
axiosInstance.interceptors.response.use(
    response => {
        // 응답 후 처리 작업을 수행할 수 있습니다.
        // console.log("종료 인터셉터",response.config.url);
        // store.commit('setLoading', false); // Vuex 스토어를 사용하는 경우
        return response;
    },
    error => {
        console.log(error)
        // store.commit('setLoading', false); // Vuex 스토어를 사용하는 경우
        return Promise.reject(error);
    }
);
// Axios 인스턴스를 Vue 프로토타입에 주입
Vue.prototype.$axios = axiosInstance;

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App),
    store,
}).$mount('#app')
