const joinStore = {
    namespaced: true,
    state: {
        isValidName: false,
        isValidEmail: false,
        isValidPW: false,
        isValidTerms: false,
    },
    getters: {
        getNameValid: state => state.isValidName,
        getEmailValid: state => state.isValidEmail,
        getPWValid: state => state.isValidPW,
        getTermsValid: state => state.isValidTerms,
    },
    mutations: {
        updateNameValid: (state, payload) => {
            state.isValidName = payload;
        },
        updateEmailValid: (state, payload) => {
            state.isValidEmail = payload;
        },
        updatePWValid: (state, payload) => {
            state.isValidPW = payload;
        },
        updateTermsValid: (state, payload) => {
            state.isValidTerms = payload;
        },
    },
    actions: {
        setNameValid: ({ commit }, payload) => {
            commit('updateNameValid', payload);
        },
        setEmailValid: ({ commit }, payload) => {
            commit('updateEmailValid', payload);
        },
        setPWValid: ({ commit }, payload) => {
            commit('updatePWValid', payload);
        },
        setTermsValid: ({ commit }, payload) => {
            commit('updateTermsValid', payload);
        },
        setInitilize:({ commit }) => {
            commit('updateNameValid', false);
            commit('updateEmailValid', false);
            commit('updatePWValid', false);
            commit('updateTermsValid', false);
        },
    }
}

export default joinStore
