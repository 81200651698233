const findpwStore = {
    namespaced: true,
    state: {
        isValidEmail: false,
        isLoading: false,
    },
    getters: {
        getEmailValid: state => state.isValidEmail,
        getLoading: state => state.isLoading
    },
    mutations: {
        updateEmailValid: (state, payload) => {
            state.isValidEmail = payload;
        },
        updateLoading: (state, payload) => {
            state.isLoading = payload;
        }
    },
    actions: {
        setEmailValid: ({ commit }, payload) => {
            commit('updateEmailValid', payload);
        },
        setLoading: ({ commit }, payload) => {
            commit('updateLoading', payload);
        },
        setInitilize:({ commit }) => {
            commit('updateEmailValid', false);
            commit('updateLoading', false);
        },
    }
}

export default findpwStore
