const secessionStore = {
    namespaced: true,
    state: {
        userName: false,
    },
    getters: {
        getUserName: state => state.userName,
    },
    mutations: {
        updateUserName: (state, payload) => {
            state.userName = payload;
        },
    },
    actions: {
        setUserName: ({ commit }, payload) => {
            console.log(payload);
            commit('updateUserName', payload);
        },
    }
}

export default secessionStore
