const searchboxStore = {
    namespaced: true,
    state: {
        activeButton: 'search_keyword_list', // search_lookup_list_btn
        activeList: 'search_keyword_list'
    },
    getters: {
        getActiveButton: state => state.activeButton,
        getActiveList: state => state.activeList,
    },
    mutations: {
        updateActiveButton: (state, payload) => {
            state.activeButton = payload;
        },
        updateActiveList: (state, payload) => {
            state.activeList = payload;
        },
    },
    actions: {
        setActiveList: ({ commit }, payload) => {
            commit('updateActiveButton', payload);
            commit('updateActiveList', payload);
        },
        setInitilize:({ commit }) => {
            commit('updateSearchON', true);
            commit('updateActiveList', 'search_lookup_list');
        },
    }
}

export default searchboxStore
